<template>
  <v-container>
    <v-row class="mt-12">
      <v-col cols="12" align="center">
        <h1>Du er logget ut</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="center">
        <v-btn @click="loginMs" class="primary">
          {{ $t("logInWithMs") }}
        </v-btn>
      </v-col>
      <v-col cols="12" align="center">
        <v-btn @click="loginV24">
          {{ $t("logInWithGA") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoadingSpinner from "@/components/misc/LoadingSpinner";
import config from "@/config/config";

export default {
  methods: {
    loginMs() {
      window.location.href = "/sso-ms-login"
    },
    loginV24() {
      window.location.href = config.urlApp;
    }
  },

  name: "SsoMicrosoftLoggedout"
};
</script>
